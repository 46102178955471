@font-face {
  font-family: "Lexend";
  src: local("Lexend"), url(../assets/fonts/Lexend/Lexend-VariableFont_wght.ttf) format('truetype')
}

:root {
  --theme-colour-orange: #FF9456;
  --theme-colour-steel: #323F49;
  --theme-colour-red: #D85A62;
  --theme-colour-green: #6AB97B;
  --theme-colour-blue: #2D8CC5;
  --background-colour-1: #EFEFEF;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  display: block;
  float: none;
  line-height: normal;
  position: static;
  z-index: auto;
}

#h2 {
  font-weight: 600;
}



.backgroundColor {
  height: 100%;
}

.header {
  /*height:4%*/
  background: var(--background-colour-1);
  color: black;
  box-shadow: none;
  display: fixed
}

.page {
  min-height: 95%;
  width: 100%;
  background-color: var(--background-colour-1);

}

.logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .home {
    padding: 100
  }
}